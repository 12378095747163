<template>
  <v-container fluid>
    <Datatable
      v-if="$_HASPERMISSION('administrador', getSession.user.id_tipo_utilizador)"
      :TableItems="TableItems"
      :TableHeaders="TableHeaders"
      :FormFields="FormFields"
      :TableActions="TableActions"
      :ModalNewText="$t(`datatables.${NAME2}.ModalNewText`)"
      :ModalUpdateText="$t(`datatables.${NAME2}.ModalUpdateText`)"
      :ModalDeleteText="$t(`datatables.${NAME2}.ModalDeleteText`)"
      :DeleteDialogTitle="$t(`datatables.${NAME2}.DeleteDialogTitle`)"
      :TableTitle="$t(`datatables.${NAME2}.TableTitle`)"
      :Store="NAME"
      :Store2="NAME2"
    />
  </v-container>
</template>
<script>
import { _MODELS } from "@/store/_MAIN"
const _NAME = "UTILIZADOR"
const _NAME2 = "ADMINISTRADOR"
export default {
  components: {
    Datatable: () => import("@/components/Datatable")
  },
  computed: {
    getSession() {
      return this.$store.getters["session/getState"]
    },
    TableItems() {
      return this.$store.getters[`${this.NAME}/getAdministradores`].map(el => {
        el.tipo_utilizador = this.$store.getters["tipo_utilizador/getById"](
          el.id_tipo_utilizador
        )
        return el
      })
    }
  },
  data: () => ({
    TableActions: _MODELS[_NAME]._ACTIONS,
    FormFields: _MODELS[_NAME]._FIELDS,
    TableHeaders: _MODELS[_NAME]._HEADERS,
    NAME: _NAME.toLowerCase(),
    NAME2: _NAME2.toLowerCase()
  })
}
</script>
